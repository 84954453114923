import React, { useEffect, useContext } from 'react';
import { Builder } from '@builder.io/react';
import useAuth from '../../hooks/useAuth';
import FunnelContext from '../../context/FunnelContext';

import {
  fetchUsersActiveSubscriptions,
  retryPaymentsSelectedSubscriptions,
  addNextMonthRebillDiscountTag
} from '../../utils/activeSubscriptions';

const NextRebillDiscount = ({ discountTag }) => {
  const { token, loading } = useAuth();
  const { path } = useContext(FunnelContext);
  useEffect(() => {
    if (!loading) {
      fetchUsersActiveSubscriptions(token)
        .then(({ data: { data } }) => {
          return data?.filter(item => item.status === 5);
        })
        .then(data => {
          const ids = data.map(item => item.id);
          return {
            ids,
            retry:
              ids.length === 0
                ? null
                : retryPaymentsSelectedSubscriptions(ids, token, path)
          };
        })
        .then(({ ids, retry }) => {
          retry?.then(({ data }) => {
            if (data.status === 'Success') {
              ids.forEach(id => {
                addNextMonthRebillDiscountTag(token, id, discountTag);
              });
            }
          });
        });
    }
  }, [token, loading, discountTag, path]);

  if (!Builder.isEditing) return null;

  return (
    <center>
      <small>- - Next Month Rebill Discount Tag - -</small>
      <br />
      <strong>{discountTag}</strong>
    </center>
  );
};

export default NextRebillDiscount;
